import type { ComponentType } from "react"

export function openApparel(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                onClick={() => window.open("https://saguto.golf/p/apparel")}
            />
        )
    }
}

export function openTrainingAids(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                onClick={() =>
                    window.open("https://saguto.golf/p/training-aids")
                }
            />
        )
    }
}

export function openGolfGear(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                onClick={() => window.open("https://saguto.golf/p/golf-gear")}
            />
        )
    }
}
